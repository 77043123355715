<template>
  <div class="boxx">
    <div class="topLine">
      <el-row>
        <el-col :span="10">
          <el-button
            icon="el-icon-s-operation"
            size="small"
            @click="drawer = true"
            >题目列表</el-button
          ></el-col
        >
        <el-col :span="10">
          <el-button
            icon="el-icon-arrow-left"
            size="small"
            @click="upDown(-1)"
            :disabled="upDis"
            >上一题</el-button
          >
          <el-button size="small" @click="upDown(1)" :disabled="downDis"
            >下一题<i class="el-icon-arrow-right el-icon--right"></i></el-button
        ></el-col>
      </el-row>
    </div>
    <div class="contents" v-loading="dataLoading">
      <div class="left">
        <div class="titleLine">{{ titles }}</div>
        <div class="tabsLine">
          <el-tabs
            type="border-card"
            v-model="activeName"
            @tab-click="handleClick"
          >
            <el-tab-pane label="题目" name="questions"></el-tab-pane>
            <el-tab-pane :label="tijieTotalTxt" name="qJieXi"></el-tab-pane>
          </el-tabs>
        </div>
        <div class="viewBox">
          <router-view
            ref="child"
            @getQuery="getQueryFn"
            @getTitle="getTitleFn"
            @getStuJob="getStuJobFn"
            @getStuJobNull="getStuJobNullFn"
            @getTm_px="getTm_pxFn"
            @loadingType="loadingTypeFn"
            @getTjTotal="getTjTotalFn"
          ></router-view>
        </div>
      </div>
      <div class="right">
        <div class="contentBox">
          <p style="font-size: 20px">题目附件</p>
          <div class="upLoadBox" v-if="!isDisabled">
            <el-upload
              class="upload-demo"
              drag
              action=""
              :on-change="handleChange"
              :on-remove="handleRemove"
              :file-list="fileList"
              :auto-upload="false"
            >
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">
                <em> 点击上传添加题目文件</em>
                <p style="margin-top: 10px">
                  支持各类文档、图片、代码、压缩包格式
                </p>
              </div>
            </el-upload>
          </div>
          <div class="fileUl" v-else>
            <ul>
              <li v-for="item in stuJob.file_data" :key="item.id">
                <span>{{ item.name }}</span>
                <a :href="item.path">下载</a>
              </li>
            </ul>
          </div>
          <p style="font-size: 20px">
            题目留言
            <span style="font-size: 16px; color: #74787c; margin-left: 7px"
              >选填</span
            >
          </p>
          <el-input
            style="margin-top: 10px; font-size: 20px"
            type="textarea"
            :rows="4"
            :disabled="isDisabled"
            placeholder="答案以附件形式提交，留言仅作备注使用哦！"
            v-model="textarea"
          >
          </el-input>
          <el-button
            style="margin-top: 10px"
            type="primary"
            @click="yesBtn"
            v-if="!isDisabled"
            :loading="btnLoading"
            >确认提交</el-button
          >
        </div>
      </div>
    </div>
    <progressDialog ref="progressDialog" />
    <el-drawer
      :title="tikuName"
      :visible.sync="drawer"
      direction="ltr"
      size="50%"
      :before-close="handleClose"
    >
      <div
        class="dBox"
        v-for="(it, index) in questionbankCategory_data"
        :key="it.id"
      >
        <div class="tis">
          <span>{{ ++index }}</span>
          <span>{{ it.title }}</span>
        </div>
        <div class="slis">{{ it.title }}</div>
        <ul>
          <li
            v-for="i in it.questionDetailData"
            :key="i.id"
            @click="liCk(i.id)"
          >
            <span class="t1">{{ i.title }}</span>
            <div>
              <span :class="i.type == 1 ? 't1' : i.type == 2 ? 't2' : 't3'">{{
                i.type == 1 ? "简单" : i.type == 2 ? "复杂" : "困难"
              }}</span>
              <span>{{ i.total_count }}人</span>
            </div>
          </li>
        </ul>
      </div>
    </el-drawer>
  </div>
</template>
<script>
import * as http from "@/api/order.js";
import { client } from "@/utils/oss.js";
import progressDialog from "@/components/progressDialog.vue";
export default {
  data() {
    return {
      activeName: "questions",
      isEmpty: false,
      textarea: "",
      fileList: [],
      idList: [],
      drawer: false,
      userInfo: {},
      btnLoading: false,
      routerQuery: {},
      titles: "",
      stuJob: {},
      isDisabled: false,
      tm_px: [],
      questionbankCategory_data: [],
      tijieTotal: 0,
      tijieTotalTxt: "题解",
      tikuName: "",
      question_detail_id: "",
      dataLoading: false,
      upDis: false,
      downDis: false,
    };
  },
  mounted() {
    const lengths = this.$route.path.split("/").length;
    this.activeName = this.$route.path.split("/")[lengths - 1];
    this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
    const question_id = this.$route.query.question_id;
    setTimeout(() => {
      const changdu = this.tm_px.length;
      if (this.tm_px[0].id == question_id) {
        this.upDis = true;
      }
      if (this.tm_px[changdu - 1].id == question_id) {
        this.downDis = true;
      }
    }, 300);
  },
  components: { progressDialog },
  watch: {
    question_detail_id: {
      handler(v) {
        console.log("v", v);
        let index = this.tm_px.findIndex((item) => {
          return item.id == this.question_detail_id;
        });
        this.upDis = index == 0 ? true : false;
        this.downDis = index == this.tm_px.length - 1 ? true : false;
      },
    },
  },
  methods: {
    upDown(s) {
      //   console.log(s);
      //   console.log(this.tm_px);
      //   console.log(this.question_detail_id);
      //   console.log(this.$route.query.question_id);
      this.fileList = [];
      if (this.question_detail_id) {
        let index = this.tm_px.findIndex((item) => {
          return item.id == this.question_detail_id;
        });
        if (this.tm_px[index + s]) {
          this.liCk(this.tm_px[index + s].id);
        }
        console.log("当前在tm_px中的索引位置", index);
        // console.log(this.tm_px[index + s]);
      } else {
        let index = this.tm_px.findIndex((item) => {
          return item.id == this.$route.query.question_id;
        });
        if (this.tm_px[index + s]) {
          this.liCk(this.tm_px[index + s].id);
        }
        // console.log("当前在tm_px中的索引位置", index);
        // console.log(this.tm_px[index + s]);
      }
    },
    loadingTypeFn(t) {
      this.dataLoading = t;
    },
    liCk(id) {
      this.fileList = [];
      if (this.activeName != "questions") {
        this.$router.push({ path: "questions", query: this.routerQuery });
      }
      this.activeName = "questions";
      this.$refs.child.getList(id);
      this.question_detail_id = id;
      this.drawer = false;
      this.tijieTotalTxt = `题解`;
      console.log(this.routerQuery);
      console.log(id);
    },
    getTjTotalFn(s) {
      this.tijieTotal = s;
      if (s >= 1) {
        this.tijieTotalTxt = `题解(${s})`;
      } else {
        this.tijieTotalTxt = `题解`;
      }
    },
    getTm_pxFn(s) {
      this.tm_px = s;
    },
    getStuJobFn(s) {
      this.stuJob = s;
      this.textarea = s.data.content;
      this.isDisabled = true;
    },
    getStuJobNullFn() {
      this.isDisabled = false;
      this.textarea = "";
    },
    getQueryFn(s) {
      console.log(s);
      this.questionbankCategory_data = JSON.parse(s.questionbankCategory_data);
      this.tikuName = s.tikuName;
      this.routerQuery = s;
    },
    getTitleFn(s) {
      this.titles = s;
    },
    yesBtn() {
      this.$confirm("确认提交作业？")
        .then((_) => {
          let params = {
            content: this.textarea,
            question_detail_id: this.routerQuery.question_id,
            fileList: this.fileList,
            student_id: this.userInfo.id,
            grade_id: sessionStorage.getItem("gradeID"),
          };
          this.btnLoading = true;
          http
            .grade_question_detail_answer({ ...params })
            .then((res) => {
              this.$message({
                type: "success",
                message: res.msg,
              });
              this.fileList = [];
              this.btnLoading = false;
              this.$refs.child.getList(this.routerQuery.question_id);
            })
            .catch((_) => {
              this.btnLoading = false;
            });
        })
        .catch((_) => {});
    },
    handleClose() {
      this.drawer = false;
    },
    handleRemove(file) {
      console.log(file, this.fileList);
      let index = this.fileList.findIndex((item) => {
        return item.name == file.name;
      });
      // this.delId.push(this.fileList[index].id);
      this.fileList.splice(index, 1);
      // let index2 = this.fileList.findIndex((item) => {
      //   return item.id == file.id;
      // });
      // this.idList.splice(index2, 1);
    },
    handleChange(file) {
      console.log(file);
      let nunmber = new Date().getTime();
      var fileName = "学生作业/" + nunmber + file.name;
      console.log(fileName);
      let that = this;
      client()
        .multipartUpload(fileName, file.raw, {
          progress: function (percentage, cpt) {
            if (!that.closePage) {
              that.$refs.progressDialog.show(
                Math.floor(percentage * 100),
                file,
                nunmber
              );
            } else {
              that.closePage = false;
              client.cancel();
            }
          },
        })
        .then((res) => {});
    },
    handleClick(tab) {
      if (this.question_detail_id) {
        this.routerQuery.question_id = this.question_detail_id;
      }
      this.$router.push({ path: tab.name, query: this.routerQuery });
    },
  },
};
</script>
<style lang="scss" scoped>
.boxx {
  width: 100vw;
  height: 100vh;
  overflow: auto;
  background: #f5f6f7;
  display: flex;
  flex-direction: column;
  .topLine {
    width: 100%;
    padding: 10px 16px;
    box-sizing: border-box;
    background: #fff;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.04);
  }
  .contents {
    flex: 1;
    margin-top: 12px;
    background: #fff;
    display: flex;
    .left {
      width: 60%;
    }
    .right {
      flex: 1;
      border-left: 3px dashed #f3f4f5;
    }
    .titleLine {
      background: #fff;
      color: #333;
      font-size: 18px;
      font-weight: 700;
      padding: 10px 16px;
      box-sizing: border-box;
    }
    .tabsLine,
    .viewBox {
      padding: 0px 16px;
      box-sizing: border-box;
    }
  }
}
.contentBox {
  width: 100%;
  border: 1px solid #dadce0;
  border-radius: 0 0 8px 8px;
  border-top: none;
  padding: 24px;
  box-sizing: border-box;
  .upLoadBox {
    padding: 20px 0;
    box-sizing: border-box;
  }
}
.dBox {
  padding: 10px;
  box-sizing: border-box;
  .tis {
    font-size: 18px;
    font-weight: 700;
    span {
      &:first-child {
        background: linear-gradient(135deg, #ffc680 0%, #fd7023 100%);
        border-radius: 10px;
        color: #fff;
        content: counter(section, decimal-leading-zero);
        counter-increment: section;
        font-variant: tabular-nums;
        margin-right: 10px;
        padding: 0 12px;
      }
    }
  }
  .slis {
    color: #555555;
    padding: 8px 12px;
    box-sizing: border-box;
    background-color: #f8f8f8;
    border-radius: 14px 14px 0 0;
    font-weight: 700;
    margin-top: 10px;
    font-size: 16px;
  }
  ul {
    margin-top: 10px;
    li {
      display: flex;
      font-size: 14px;
      line-height: 42px;
      padding-left: 30px;
      box-sizing: border-box;
      border: 1px solid #fff;
      position: relative;
      &:hover {
        background-color: #f8f9fb;
        border: 1px solid #f3f3f6;
        cursor: pointer;
      }
      .t1 {
        display: inline-block;
        width: 60%;
        &::before {
          content: "";
          display: block;
          width: 6px;
          height: 6px;
          border-radius: 50%;
          background: #ddd;
          position: absolute;
          left: 10px;
          top: 40%;
        }
      }
      div {
        padding: 0 6%;
        box-sizing: border-box;
        flex: 1;
        display: flex;
        justify-content: space-between;
      }
    }
  }
}
.fileUl {
  margin: 10px 0 20px 0;
  li {
    padding: 10px;
    box-sizing: border-box;
    border: 1px solid #ebecee;
    border-radius: 10px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    a {
      text-decoration: none;
      color: #488df5;
    }
  }
}
.t1 {
  color: #3b97ff;
}
.t2 {
  color: #32ca99;
}
.t3 {
  color: #ffaa20;
}
::v-deep .el-tabs__content {
  display: none;
}
::v-deep .el-tabs--border-card {
  background: none;
  border: none;
  box-shadow: none;
}
::v-deep .el-tabs--border-card > .el-tabs__header .el-tabs__item.is-active {
  background: #409eff;
  color: #fff;
}
::v-deep .el-tabs__item {
  padding: 0 45px;
}
::v-deep .el-upload,
::v-deep .el-upload-dragger {
  width: 100%;
}
::v-deep .el-upload-dragger {
  padding: 20px 0 0 0;
  box-sizing: border-box;
  height: 260px;
  background: #f8f9fa;
  border: none;
}
::v-deep .el-upload-list__item .el-icon-upload-success {
  display: none;
}
::v-deep .el-upload-list__item {
  padding: 20px 10px;
  box-sizing: border-box;
  border-radius: 8px;
  border: 1px solid #dadce0;
  font-size: 20px;
  margin-bottom: 20px;
}
::v-deep .el-upload-list__item .el-icon-close {
  position: absolute;
  top: 40%;
  right: 20px;
}
::v-deep .el-drawer__header {
  margin-bottom: 10px;
}
</style>
